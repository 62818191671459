export enum ProjectLanguageOption {
  JAVASCRIPT = 'Javascript',
  TYPESCRIPT = 'TypeScript',
  PYTHON = 'Python',
  GO = 'Go',
  JAVA = 'Java',
  KOTLIN = 'Kotlin',
  PHP = 'PHP',
  RUBY = 'Ruby',
  CSHARP = 'C#',
  CPP = 'C++',
  C = 'C',
  SCALA = 'Scala',
  OTHERS = 'Others',
}
export enum ProjectTypeOption {
  ANDROID = 'Android',
  IOS = 'IOS',
  WEB = 'Web',
  DESKTOP = 'Desktop',
  XAMARIN = 'Xamarin',
  MAUI = 'MAUI',
  XR = 'XR',
}

export enum ProjectUseSDKRestApi {
  SDK = 'SDK',
  REST_API = 'Rest API',
}

export enum ProjectDivisionOption {
  SPIME = 'Spime',
  TRANSPORTATION = 'Transportation',
  AGRICULTURE = 'Agriculture',
  WATER_RESOURCES = 'Water Resources',
  CONSTRUCTION = 'Construction',
  OTHERS = 'Others'
}

export enum ProjectStatusOption {
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
  ABORTED = 'ABORTED',
  UNKNOWN = 'UNKNOWN',
}

export enum ProjectTrackDefaultEventsOption {
  YES = 'YES',
  NO = 'NO',
}
